const pageUrls = {
  home: '/',
  security: '/security/',
  downloadStore:
    'https://redirect.appmetrica.yandex.com/serve/245151619962981403',
  downloadStoreTestB:
    'https://redirect.appmetrica.yandex.com/serve/821563068659018466',
  downloadStoreTestC:
    'https://redirect.appmetrica.yandex.com/serve/245102318603740825',

  //пример с параметром
  // offer: (variant?: keyof typeof variants): string => {
  //   if (variant) {
  //     return `/user/offer/?variant=${variant}`;
  //   }
  //
  //   return '/user/offer/';
  // },˚
};

export default pageUrls;
