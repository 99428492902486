import ALink from '@a3/chameleon/src/components/basic/ALink';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import type { ReactNode } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import DesktopMenu from 'components/HeroBlock/components/DesktopMenu';
import MobileMenu from 'components/HeroBlock/components/MobileMenu';
import { ImagesPathEnum } from 'components/HeroBlock/components/types';
import { HtmlHead } from 'components/layouts/HtmlHead';

import ContentContainer from '../../basic/ContentContainer';
import Footer from '../Footer';

import s from './Page.module.scss';

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  className?: string;
  grid?: boolean;
  removeBottomPadding?: boolean;
  seo?: {
    title?: string;
    description?: string;
    canonicalUrl?: string;
  };
  style?: React.CSSProperties;
}

export default function Page({
  withoutFooter,
  withoutHeader,
  style,
  className,
  fullHeight,
  fullWidth,
  removeBottomPadding,
  grid,
  children,
  seo,
}: Props): JSX.Element {
  const { clickAnalytics } = useAnalytics();

  return (
    <>
      <HtmlHead {...seo} />

      {!withoutHeader && (
        <ContentContainer className={s.headerWrapper}>
          <ALink
            className={s.logo}
            onClick={(): void => {
              window.open(location.origin, '_self');
              clickAnalytics(gtmEvents.clickHeaderLogo);
            }}
          >
            <Image
              src={ImagesPathEnum.blackLogo}
              width={164}
              height={32}
              alt="Логотип сервиса"
              priority
            />
          </ALink>

          <DesktopMenu isBlack />
          <MobileMenu isBlack />
        </ContentContainer>
      )}
      <div className={s.root} style={style}>
        <div
          className={cn(s.mainWrapper, {
            [s.mainWrapperWithoutHeader]: withoutHeader,
          })}
        >
          <main
            className={cn(s.main, className, {
              grid,
              [s.fullHeight]: fullHeight,
              [s.fullWidth]: fullWidth,
              [s.removeBlockEndPadding]: removeBottomPadding,
            })}
          >
            {children}
          </main>
        </div>
        {!withoutFooter && (
          <ContentContainer className={s.footerWrapper}>
            <Footer />
          </ContentContainer>
        )}
      </div>
    </>
  );
}
