import type { NextRouter } from 'next/router';

import type {
  ClickAnalyticsFunctionType,
  ClickEvent,
} from 'constants/gtmEvent';

interface HandleScrollAndToggleTypes {
  router: NextRouter;
  classBlock: string;
  analyticsEvent: ClickEvent;
  clickAnalytics: ClickAnalyticsFunctionType;
  isOpen?: boolean;
  setIsOpen?: (prev: boolean) => void;
}

const scrollToBlock = (className: string): void => {
  const block = document.querySelector<HTMLDivElement>(className);

  if (block !== null) {
    block.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }
};

const handleScrollAndToggle = ({
  router,
  classBlock,
  isOpen,
  setIsOpen,
  clickAnalytics,
  analyticsEvent,
}: HandleScrollAndToggleTypes): void => {
  clickAnalytics(analyticsEvent);

  if (router.asPath === '/') {
    scrollToBlock(classBlock);
  } else {
    window.open(location.origin, '_self');
    scrollToBlock(classBlock);
  }

  if (setIsOpen) {
    setIsOpen(!isOpen);
  }
};

export { handleScrollAndToggle };
